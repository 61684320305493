<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/2">
					<h6 class="w-1/1">&nbsp;</h6>
					<div class="flex">
						<vSelect class="w-72 mr-4" v-model="opt.dateType" :clearable="false" :options="dateTypeOpts" />

						<b-form-radio class="w-20 mt-1.5" v-model="opt.period" name="searchPeriod" value="">
							<span class="mr-2">전체</span>
						</b-form-radio>
						<b-form-radio class="mt-1.5" v-model="opt.period" name="searchPeriod" value="period" />

						<datePicker placeholder="from" model="opt.startDate" />
						<span class="p-2 lh-6">~</span>
						<datePicker placeholder="to" model="opt.endDate" />
					</div>
				</div>
				<div class="w-1/6 pl-4">
					<h6>정산 상태</h6>
					<vSelect v-model="opt.calculateState" :clearable="false" :options="calculateStateOpts"/>
				</div>
				<div class="w-1/6 pl-4">
					<h6>딜 요청상태</h6>
					<vSelect v-model="opt.dealStatus" :clearable="false" :options="statusOpts" />
				</div>
				<div class="w-1/6 pl-4">
					<h6>딜 유형</h6>
					<vSelect v-model="opt.dealType" :clearable="false" :options="dealTypeOpts" />
				</div>
				<div class="w-1/6 pl-4">
					<h6>딜 구분</h6>
					<vSelect class="w-1/1" v-model="opt.encoreType" :clearable="false" :options="encoreTypeOpts" />
				</div>
			</div>

			<div class="flex">
				<div class="w-1/6 pr-4">
					<h6>협찬배송상태</h6>
					<vSelect
						class="w-1/1"
						v-model="opt.deliveryStatus"
						:clearable="false"
						:options="
							deliveryStatusOpts.filter(v => {
								switch (v.value) {
									case '':
									case '2':
									case '6':
									case '7':
									case '18':
									case '20':
										return true
								}
								return false
							})
						"
					/>
				</div>

				<div class="w-1/6 pr-4">
					<h6>핫티스트 등급</h6>
					<vSelect
						class="w-1/1"
						v-model="opt.influencerClass"
						:clearable="false"
						:options="influencerClassOpts"
					/>
				</div>

				<div class="w-1/6 pr-4">
					<h6>담당자</h6>
					<vSelect class="w-1/1" v-model="opt.managerSeq" :clearable="false" :options="managerSeqOpts" />
				</div>

				<div class="w-1/2">
					<h6>검색어</h6>
					<div class="flex">
						<vSelect class="w-80" v-model="opt.searchKeyName" :clearable="false" :options="keywordOpts" />
						<b-form-input
							class="mx-4"
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="w-40 px-6" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<b-card>
			<div class="absolute top-0 right-0">
				<vSelect
					class="w-60 mb-4 inline-block mr-4"
					v-model="calculateState"
					:clearable="false"
					:options="calculateStateOpts.slice(1)"
				/>
				<input class="w-120 form-control mr-4" :class="{'inline-block': calculateState.value==3, 'none': calculateState.value!=3}" v-model.trim="calculateReason" placeholder="정산 보류 사유" type="text" />
				<b-button class="inline-block px-4 mr-8" variant="orange" @click="changeCalculateStateAll">정산상태변경</b-button>

				<vSelect
					class="w-60 mb-4 inline-block mr-4"
					v-model="allStatus"
					:clearable="false"
					:options="statusOpts.slice(2)"
				/>
				<b-button class="inline-block px-4" @click="changeStatusAll">일괄상태변경</b-button>
				<b-button class="inline-block ml-8" @click="excelExport(false)" variant="info"
					>엑셀 다운로드 요청</b-button
				>
				<b-button
					v-if="$acl.get[0] == rule.hottistManager"
					class="inline-block ml-8"
					@click="excelExport(true)"
					variant="orange"
					>엑셀 다운로드 요청(연락처 포함)</b-button
				>
			</div>

			<tb class="mt-12" :inf="inf" :res="res" checkBoxKey="requestIdx" limit="30" />
		</b-card>
		<dealPopup
			:contentCategoryOpts="contentCategoryOpts"
			:contentQualityOpts="contentQualityOpts"
			:deliveryCompany="deliveryCompany"
			:deliveryStatus="deliveryStatusOpts"
			:hottistType="hottistTypeOpts"
			:menuTabType="menuTabType"
			:pr="ths"
			:productDeliveryType="productDeliveryType"
			:statusOpts="statusOpts"
			cName="dealPopup"
		/>
		<hottistPopup
			:contentCategoryOpts="contentCategoryOpts"
			:contentQualityOpts="contentQualityOpts"
			:hottistTypeOpts="hottistTypeOpts"
			:pr="ths"
			:userTypeOpts="userTypeOpts"
			cName="hottistPopup"
		/>
		<dealCalendarPopup :pr="ths" cName="dealCalendarPopup" />
		<sellResultPopup :pr="ths" cName="sellResultPopup" />
	</div>
</template>

<script>
import dealPopup from 'pages/dealPopup'
import hottistPopup from 'pages/hottistPopup'
import dealCalendarPopup from 'pages/dealCalendarPopup'
import sellResultPopup from 'pages/sellResultPopup'

const now = new Date(),
	dateTypeOpts = [
		{ label: '딜 진행일', value: 'dealDuring' },
		{ label: '딜 시작일', value: 'dealStart' },
		{ label: '딜 종료일', value: 'dealEnd' },
		{ label: '딜 신청일', value: 'dealRequest' },
		{ label: '딜 수락일', value: 'dealAccept' },
	],
	//딜 요청 상태
	statusOpts = [
		{ label: '전체', value: '' },
		{ label: '딜 신청', value: 'N', searchAccepted: false },
		{ label: '딜 수락', value: 'O', searchAccepted: true, inputAccepted: false },
		{ label: '딜 거절', value: 'D', searchAccepted: false, inputAccepted: false },
		{ label: '딜 신청 취소', value: 'C', searchAccepted: false, inputAccepted: false },
		{ label: '파트너사 거절', value: 'P', searchAccepted: false, inputAccepted: false },
		{ label: '핫트 관리자 취소', value: 'F', searchAccepted: true, inputAccepted: true },
		{ label: '파트너사 취소', value: 'P', searchAccepted: true, inputAccepted: true },
		{ label: '핫티스트 취소', value: 'C', searchAccepted: true, inputAccepted: true },
	],
	dealTypeOpts = [
		{ label: '전체', value: '' },
		{ label: '일반', value: 0 },
		{ label: '내돈내산', value: 1 },
		{ label: '선 제안', value: 2 },
	],
	encoreTypeOpts = [
		{ label: '전체', value: 0 },
		{ label: '신규', value: 1 },
		{ label: '앵콜', value: 2 },
		{ label: '자동 앵콜', value: 3 },
	],
	keywordOpts = [
		{ label: '딜 요청 번호', value: 'idx' },
		{ label: '딜 번호', value: 'openDealNum' },
		{ label: 'idx', value: 'productGroupIdx', operation: 'equal' },
		{ label: '딜명', value: 'dealName' },
		{ label: '이름', value: 'memberName' },
		{ label: '인스타 아이디', value: 'currentInstaId' },
		{ label: '핫트 아이디', value: 'hottId' },
		{ label: '휴대폰 번호', value: 'phone' },
		{ label: '파트너사', value: 'companyName' },
	],
	calculateStateOpts = [
		{ label: '전체', value: '' },
		{ label: '해당 없음', value: 0 },
		{ label: '정산 예정', value: 1 },
		{ label: '정산 완료', value: 2 },
		{ label: '정산 보류', value: 3 },
	],
	defOpt = {
		dateType: dateTypeOpts[0],

		period: 'period',
		startDate: getMonth(now, -1),
		endDate: now,

		calculateState: calculateStateOpts[0],
		dealStatus: statusOpts[0],
		dealType: dealTypeOpts[0],
		encoreType: encoreTypeOpts[0],
		influencerClass: '',
		managerSeq: { label: '전체', value: '' },
		searchKeyName: keywordOpts[0],
		searchKeyword: '',
	}

export default {
	components: { dealPopup, hottistPopup, dealCalendarPopup, sellResultPopup },
	props: { searchId: { default: 'def' } },
	data() {
		return {
			dateTypeOpts,
			statusOpts,
			managerSeqOpts: [],
			keywordOpts,
			deliveryStatusOpts: [],

			userTypeOpts: [],
			hottistTypeOpts: [],
			contentQualityOpts: [],
			contentCategoryOpts: [],
			influencerClassOpts: [],

			calculateStateOpts,
			calculateState: calculateStateOpts[1],
			calculateReason: '',

			menuTabType: [],
			deliveryCompany: [],
			productDeliveryType: [],

			dealTypeOpts,
			encoreTypeOpts,
			opt: { ...defOpt },
			defOpt,
			table: '',

			allStatus: statusOpts[2],

			inf: [
				{ model: 'checkAll' },
				{ title: 'No.', model: 'idx', size: 2 },
				{
					title: '요청 번호',
					model: 'button',
					size: 2,
					text: item => item.requestIdx,
					bVariant: 'no',
					class: 'text-primary',
					func: item => this.dealPopup.open(item),
				},
				{ title: '딜 번호', key: 'openDealNum', size: 2 },
				{ title: '딜 상태', key: 'dealStatus', size: 2 },
				{ title: '딜 유형', key: 'dealType', size: 2 },
				{ title: '딜 구분', key: 'encoreType', size: 2 },
				{
					title: '이름',
					model: 'button',
					size: 2,
					text: item => item.memberName,
					bVariant: 'no',
					class: 'text-primary',
					func: item => this.hottistPopup.open(item, true, true),
				},
				{
					title: '핫티<br>등급',
					key: 'hottistClass',
					size: 1,
				},
				{ title: '인스타아이디', key: 'currentInstaId', model: 'insta', size: 3 },
				{
					title: '핫트아이디',
					key: 'hottId',
					size: 3,
					sortable: 1,
				},
				{ title: 'idx', key: 'productGroupIdx', size: 2 },
				{ title: '딜명', key: 'dealName', size: 6, notCenter: 1, sortable: 1 },
				{ title: '딜시작일', key: 'startDate', size: 3, sortable: 1 },
				{ title: '딜종료일', key: 'endDate', size: 3, sortable: 1 },
				{ title: '딜신청일', key: 'requestDate', size: 3, sortable: 1 },
				{ title: '딜수락일', key: 'dealAcceptDay', size: 3, sortable: 1 },
				{ title: '정산상태', key: 'calculateState', size: 3, sortable: 0 },
				{
					title: '달력',
					model: 'button',
					icon: 'calendar4-week',
					size: 2,
					func: this.openCalendar,
				},
				{
					title: '이력',
					model: 'button',
					icon: 'list-check',
					size: 2,
					func: item => window.open('/deal_list/' + item.hottId),
				},
				{
					title: '판매결과',
					model: 'button',
					icon: 'graph-up',
					size: 2,
					func: this.openSellResult,
				},
				{ title: '변경<br>내역', model: 'memoBtn', domain: 'DEAL_REQUEST_HISTORY', readOnly: true, size: 2 },
				{ title: '메모', model: 'memoBtn', domain: 'MEMBER', size: 1, isDealMemo: 2 },
			],
			lastOpt: {},
			res: {},

			rule,
		}
	},
	methods: {
		search(operation = 'LIKE') {
			if (typeof operation != 'string') operation = 'LIKE'

			const opt = this.opt,
				calculateState = getDropdownValue( opt.calculateState ),
				dealType = getDropdownValue(opt.dealType),
				dealStatus = getDropdownValue(opt.dealStatus),
				deliveryStatus = getDropdownValue(opt.deliveryStatus),
				hottistClass = getDropdownValue(opt.influencerClass),
				managerSeq = getDropdownValue(opt.managerSeq),
				error = [],
				data = {
					orderBy: getOrder('startDate'),
					paging: getPaging(0, 30),
					encoreType: getDropdownValue(opt.encoreType),
					includePhone: false,
				}

			if( calculateState !== '' )
				data.calculateState = calculateState
			if (dealStatus) {
				data.dealStatus = dealStatus
				data.isAccepted = opt.dealStatus.searchAccepted
			}
			if (dealType !== '') data.dealType = dealType
			if (hottistClass) data.hottistClass = hottistClass

			if (opt.period) {
				if (opt.startDate > opt.endDate) error.push(opt.dateType.label + '의 시작일이 종료일보다 큽니다')
				else {
					const dateKey = getDropdownValue(opt.dateType)
					data.dateKey = dateKey
					data[dateKey + 'StartDate'] = get_time('d', 0, '', opt.startDate)
					data[dateKey + 'EndDate'] = get_time('d', 0, '', opt.endDate)
				}
			}

			if (deliveryStatus) data.deliveryStatus = deliveryStatus * 1

			if (managerSeq) data.managerSeq = managerSeq

			if (opt.searchKeyword) {
				const searchKey = getDropdownValue(opt.searchKeyName)
				if (searchKey == 'idx') data.dealRequestIdx = opt.searchKeyword
				else data.searchKey = getSearchKey([opt.searchKeyName], opt.searchKeyword, operation)
			}

			if (error.length) alert.w(error)
			else {
				this.tb.clearChecked()
				this.lastOpt = data
				this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('/api/backoffice/dealSelect/dealList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						v.requestIdx = v.idx
						v.dealStatus = v.statusName

						v.hottistType = this.hottistTypeOpts.filter(o => o.value == v.hottistType)
						if (v.hottistType && v.hottistType[0]) v.hottistType = v.hottistType[0].label
						if (!v.dealName) v.dealName = `${v.brandName} ${v.productGroupName}`

						v.name = v.memberName
						return v
					})
					this.res = res
				} else alert.excel()
			})
		},
		excelExport(isIncludePhone = false) {
			if (isIncludePhone && this.$acl.get[0] != rule.hottistManager) return alert.w('다운로드 권한이 없습니다')

			const opt = this.lastOpt,
				ex = new excel()
			if (opt.dateKey)
				ex.date(
					opt[opt.dateKey + 'StartDate'],
					opt[opt.dateKey + 'EndDate'],
					getOptsLabel(dateTypeOpts, opt.dateKey)
				)

			ex.dropdown(opt.dealStatus, statusOpts, '딜 요청상태')
			ex.dropdown(opt.dealType, dealTypeOpts, '딜 유형')
			ex.dropdown(opt.encoreType, encoreTypeOpts, '딜 구분')
			ex.dropdown(opt.deliveryStatus, this.deliveryStatusOpts, '협찬배송상태')
			ex.dropdown(opt.hottistClass, this.influencerClassOpts, '핫티스트 등급')
			ex.dropdown(opt.managerSeq, this.managerSeqOpts, '담당자')

			if (opt.dealRequestIdx) {
				ex.key('검색 조건', '딜 요청 번호')
				ex.key('검색어', opt.dealRequestIdx)
			} else ex.search(opt.searchKey, keywordOpts)

			opt.includePhone = isIncludePhone
			ex.go(this)
		},
		getChargeAdd(arr, dealStatus) {
			return new Promise(resolve => {
				if (dealStatus != 'O') resolve([])
				else {
					//딜 수락이면서 핫티스트가 1등급일 때만 추가수수료 따짐
					const dealChargeAddInfos = arr
						.filter(v => v.hottistClass == '1')
						.map(v => {
							return {
								dealRequestIdx: v.idx,
								hottistClass: 1,
								productGroupIdx: v.productGroupIdx,
							}
						})
					if (dealChargeAddInfos.length)
						postApi('api/backoffice/dealSelect/chargeAdd', { dealChargeAddInfos }).then(res => {
							resolve(res)
						})
					else resolve([])
				}
			}).then(res => {
				return arr.map(v => {
					const chargeAdd = res.filter(r => r.dealRequestIdx == v.idx)
					return {
						idx: v.idx,
						dealStatus,
						isAccepted: this.allStatus.inputAccepted,
						chargeAdd: chargeAdd.length ? chargeAdd[0].chargeAdd : 0,
					}
				})
			})
		},
		updateDealStatus(data, afterFunc) {
			return putApi('api/backoffice/dealHandle/updateStatus', data, false, false).then(res => {
				if (res.success == '0000') {
					alert.s(`딜 상태 ${data.length > 1 ? '일괄' : ''} 변경에 성공했습니다`)
					if (typeof afterFunc == 'function') afterFunc()
				} else {
					let msg =
						'아래 딜 상태 변경에 실패했습니다\r\n' +
						res.data.invalidDealRequests
							.map(e => `${e.idx} / ${e.hottId} / ${e.productGroupIdx} / ${e.statusName}`)
							.join('\r\n')
					alert.w(msg)
					throw res
				}
			})
		},
		changeCalculateStateAll() {
			const item = this.tb.getChecked(),
				calculateState = this.calculateState.value
			if( item.length ) {
				if( calculateState == 3 && !this.calculateReason )
					return alert.w( '정산 보류 사유를 입력해주세요' )
				confirm( `${ item.length }개의 딜의 정산 상태를 ${ this.calculateState.label }로 변경합니다`, () => {
					const dealRequestIdxList = item.reduce( ( prev, curr ) => {
						prev.push( curr.requestIdx )
						return prev
					}, [] )
					putApi( 'api/backoffice/dealHandle/updateCalculateState', {
						dealRequestIdxList,
						calculateState,
						calculateReason: this.calculateReason
					} ).then( () => {
						alert.s('정산 상태 변경이 완료되었습니다')
						reload( this, true )
					} )
				} )
			}
			else alert.w( '선택된 딜이 없습니다' )
		},
		changeStatusAll() {
			const item = this.tb.getChecked()
			if (item.length) {
				this.checkRestrictedDate(item).then(() => {
					const dealStatus = this.allStatus.value
					this.getChargeAdd(item, dealStatus).then(dealStatusDtoList => {
						this.updateDealStatus({ dealStatusDtoList }, () => {
							reload(this)
							this.tb.clearChecked()
						})
					})
				})
			} else alert.w('선택된 딜이 없습니다')
		},
		checkRestrictedDate(data, isUnity = false) {
			return isRestrictedDate(data, isUnity).then(res => {
				return new Promise(resolve => {
					const func = () => {
						if (typeof afterFunc == 'function') afterFunc()
						resolve(true)
					}

					if (res) {
						const errMsg = '딜 불가기간과 겹치는 날짜가 있습니다.\r\n그래도 딜 상태를 변경하시겠습니까?'
						let msg = isUnity ? errMsg : `아래 딜의 일정에 ${errMsg}\r\n${res}`

						confirm(msg, func)
					} else func()
				})
			})
		},
		openCalendar(item) {
			this.dealCalendarPopup.open(item)
		},
		openSellResult(item) {
			this.sellResultPopup.open(item)
		},
	},
	created() {
		postApi('api/backoffice/hottistSelect/managerList').then(res => {
			this.managerSeqOpts = [{ label: '전체', value: '' }, ...res]
		})

		getConfig(
			'user_type, hottist_type, content_quality, content_category, menu_tab_type, product_type, delivery_company, delivery_status, product_delivery_type, influencer_class, calculate_state',
			false
		).then(res => {
			;['userType', 'hottistType', 'contentQuality', 'contentCategory', 'deliveryStatus', 'influencerClass', 'calculate_state'].map(
				k => {
					const options = res[upToDash(k)]
					this[k + 'Opts'] = options
					defOpt[k] = options[0]
				}
			)

			let operation = 'LIKE'
			if (this.searchId != 'def') {
				if (this.searchId.startsWith('num=')) {
					defOpt.searchKeyName = keywordOpts.filter(v => v.value == 'openDealNum')[0]
					defOpt.searchKeyword = this.searchId.split('=')[1]
				} else {
					defOpt.searchKeyName = keywordOpts.filter(v => v.value == 'hottId')[0]
					defOpt.searchKeyword = this.searchId
				}
				defOpt.period = ''
				operation = 'EQUAL'
			} else {
				defOpt.period = 'period'
				defOpt.searchKeyName = keywordOpts[0]
				defOpt.searchKeyword = ''
				operation = 'LIKE'
			}

			this.opt = { ...defOpt }

			this.menuTabType = res.menu_tab_type
			this.deliveryCompany = res.delivery_company
			this.productDeliveryType = res.product_delivery_type

			this.search(operation)
		})
	},
}
</script>
